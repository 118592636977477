import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';

export const documentIdAtom = atom('');

export type NativeFile = {
  mime_type: string;
  title: string;
  url: string;
};

export const documentRequestQueryAtom = atomWithQuery((get) => ({
  enabled: get(documentIdAtom) !== '',
  queryFn: async () => {
    const documentRequestResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/file/download/${get(documentIdAtom)}/document-requests/new`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          'Cache-Control': 'no-cache',
        },
      },
    );
    const documentRequest = await documentRequestResponse.json();

    let documentRequestList;

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    while (!documentRequestList || documentRequestList.status !== 'completed') {
      const documentRequestListResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/file/download/document-requests/${documentRequest.uuid}/result`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
            'Cache-Control': 'no-cache',
          },
        },
      );
      documentRequestList = await documentRequestListResponse.json();

      await sleep(1000);
    }

    return documentRequestList.result as NativeFile[];
  },
  queryKey: [
    'documentRequest',
    get(documentIdAtom),
    process.env.REACT_APP_API_URL,
    process.env.REACT_APP_BEARER_TOKEN,
  ],
}));
