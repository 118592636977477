// TODO: to remove in a foreseen future
// this file being used for demo the way we preview highlighted pdf file either as image or raw pdf
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfHighlight } from 'react-pdf-highlighter';

import { colors, TypeView } from '../../constants';
import { useImagePreview } from '../../hooks/useImagePreview';
import { clarityService } from '../../services';
import { FileInfoV2 } from '../../services/SearchService';
import { LoadingDots } from '../commons/LoadingDots';
import { BrokenImageIcon } from '../icons';
import { SearchTerm } from './pdf';
import { PdfViewer } from './PdfViewer';

interface PdfPreviewProps {
  detailPageUrl?: string;
  file: FileInfoV2;
  onViewPdf: (
    file: FileInfoV2,
    searchTerms: SearchTerm[],
    highlight?: PdfHighlight,
  ) => void;
  queries: string[];
  showPdfModal: boolean;
  tags: string[];
  view: TypeView;
}

interface PreviewProps {
  detailPageUrl?: string;
  file: FileInfoV2;
  onClickPdfPreview: (highlight?: PdfHighlight) => void;
  queries: string[];
  showPdfModal: boolean;
  terms: SearchTerm[];
  view: TypeView;
}

const PreviewImage: React.FC<PreviewProps> = (props) => {
  const { t } = useTranslation();
  const { detailPageUrl, file, onClickPdfPreview, queries, terms, view } =
    props;
  const { getImage } = useImagePreview();

  const [localUrl, setLocalUrl] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(true);
  const [isFailedDownload, setIsFailedDownload] = useState<boolean>(false);
  const [focusingHighlight, setFocusingHighlight] = useState<PdfHighlight>();
  const fetchImagePreview = useCallback(async () => {
    try {
      const [blobUrl] = await getImage(file, queries);

      if (!blobUrl) {
        setIsFailedDownload(true);
        return;
      }

      setLocalUrl(blobUrl);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    } finally {
      setIsProcessing(false);
    }
  }, [file, getImage, queries]);

  useEffect(() => {
    fetchImagePreview();
  }, [fetchImagePreview]);

  useEffect(() => {
    // Create new highlight for trigger re-focus highlight when view mode is changed
    focusingHighlight && setFocusingHighlight({ ...focusingHighlight });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const onFocusHighlightChange = useCallback((highlight?: PdfHighlight) => {
    setIsProcessing(false);

    if (focusingHighlight?.id !== highlight?.id) {
      setFocusingHighlight(highlight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEitherImageOrPdf = useCallback(() => {
    if (isFailedDownload) {
      return (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <BrokenImageIcon />
          <div>{t('document_preview_text_loading_failed')}</div>
        </div>
      );
    }

    return (
      <PdfViewer
        autoScale={true}
        detailPageUrl={detailPageUrl}
        focusFirstHighlight={true}
        highlightToScroll={focusingHighlight}
        onClick={onClickPdfPreview}
        onFocusHighlightChange={onFocusHighlightChange}
        pdfScale={'page-actual'}
        positions={[]}
        scrollable={false}
        searchTerms={terms}
        url={localUrl}
      />
    );
  }, [isProcessing, isFailedDownload, localUrl]);

  return (
    <div className={'position-relative h-100'}>
      <div
        className={['d-inline', isProcessing ? 'is-processing' : ''].join(' ')}
      >
        {renderEitherImageOrPdf()}
      </div>
      {isProcessing && !isFailedDownload && <LoadingDots />}
    </div>
  );
};

export const ImagePreview: React.FC<PdfPreviewProps> = (props) => {
  const {
    detailPageUrl = '',
    file,
    onViewPdf,
    queries,
    showPdfModal,
    tags,
    view,
  } = props;

  const coloredTerms: SearchTerm[] = useMemo(
    () =>
      tags.map((value, index, _) => {
        return { color: colors[index].rgba, term: value };
      }),
    [tags],
  );

  const onClickPdfPreview = useCallback(
    (highlight?: PdfHighlight) => {
      onViewPdf(file, coloredTerms, highlight);
      clarityService.trackingClickedDocument(file);
    },
    [onViewPdf, file, coloredTerms],
  );

  // we now will display image when previewing so we don't need a fallback like canPreview flag anymore
  return (
    <PreviewImage
      detailPageUrl={detailPageUrl}
      file={file}
      onClickPdfPreview={onClickPdfPreview}
      queries={queries}
      showPdfModal={showPdfModal}
      terms={coloredTerms}
      view={view}
    />
  );
};
