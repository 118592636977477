import { useQuery } from '@tanstack/react-query';

export default function useFileDownloadLink({
  enabled = true,
  uuid,
}: {
  enabled?: boolean;
  uuid: string;
}) {
  return useQuery({
    enabled: !!uuid && enabled,
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/file/download/${uuid}?file_type=pdf`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          },
        },
      ).then((res) => res.json());

      if (!response.data) {
        throw new Error('Cannot get file download link');
      }

      return response.data;
    },
    queryKey: ['fileDownloadLink', uuid],
  });
}
