import { CronJob } from 'cron';
import { useCallback, useEffect, useState } from 'react';

import { fileService, PdfOcrData, TypeUUID } from '../services/FileService';
import { FileInfoV2, FileMetadataV2 } from '../services/SearchService';

interface BlobCache {
  expired: number;
  ocrData?: PdfOcrData;
  url: string;
}

const blobCache = new Map<string, BlobCache>();
const EXPIRED_MINUTES = 30;
const EXPIRED_DURATION: number = EXPIRED_MINUTES * 60 * 1000; // 30 minutes

let lastInvoke = -1;
// job to invalidate blob cache, will run on every half of the expired duration
const job = new CronJob(
  `*/${EXPIRED_MINUTES / 2} * * * *`,
  function () {
    const now = new Date().getTime();
    if (
      lastInvoke !== -1 &&
      lastInvoke + EXPIRED_DURATION <= now &&
      blobCache.size > 0
    ) {
      lastInvoke = now;
      Array.from(blobCache.keys())
        .filter((key, index, list) => {
          return blobCache.get(key)!.expired <= now || key === '';
        })
        .forEach((key, index, list) => {
          window.URL.revokeObjectURL(blobCache.get(key)?.url || '');
          blobCache.delete(key);
        });
    }
  },
  null,
  true,
);

interface FileHook {
  getFile: (
    file: FileInfoV2,
    queries: string[],
    getFullFile?: boolean,
    updateProgress?: (progress: number) => void,
  ) => Promise<[string, PdfOcrData | undefined]>;

  getFileMetadata: (uuid: TypeUUID) => Promise<FileMetadataV2 | undefined>;
  getRawFile: (
    file: FileInfoV2,
    updateProgress?: (progress: number) => void,
  ) => Promise<string>;
}

export function useFile(): FileHook {
  const [abortController, setAbortController] = useState<AbortController>();

  const getFile = useCallback(
    async (
      file: FileInfoV2,
      queries: string[],
      getFullFile = false,
      updateProgress?: (progress: number) => void,
    ): Promise<[string, PdfOcrData | undefined]> => {
      try {
        const now = new Date().getTime();
        lastInvoke = now;

        // delete all the caches that expired and release the memory
        Array.from(blobCache.keys())
          .filter((key) => {
            return blobCache.get(key)!.expired <= now || key === '';
          })
          .forEach((key) => {
            window.URL.revokeObjectURL(blobCache.get(key)?.url || '');
            blobCache.delete(key);
          });

        // check if the not expired caches exist
        if (blobCache.has(file.uuid)) {
          // if there is the cache, refresh the expired and return the blob
          const { ocrData, url: blobUrl = '' } = blobCache.get(file.uuid) || {};

          blobCache.set(`${file.uuid}_${getFullFile}`, {
            expired: now + EXPIRED_DURATION,
            ocrData,
            url: blobUrl,
          });

          updateProgress?.(100);

          return [blobUrl, ocrData];
        } else {
          // if there is no the cache, request to server
          const [abortController, readFileAsync] = fileService.read(
            file,
            queries,
            getFullFile,
            updateProgress,
          );
          setAbortController(abortController);

          const [fileBlob, ocrData] = await readFileAsync;

          if (fileBlob !== undefined) {
            // add to cache and return the blob
            const blobUrl = window.URL.createObjectURL(new Blob([fileBlob]));

            blobCache.set(`${file.uuid}_${getFullFile}`, {
              expired: now + EXPIRED_DURATION,
              ocrData,
              url: blobUrl,
            });
            return [blobUrl, ocrData];
          } else {
            throw Error('Invalid response format');
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('file service error: ', error.message);
        }
        return ['', undefined];
      }
    },
    [],
  );
  const getRawFile = useCallback(
    async (
      file: FileInfoV2,
      updateProgress?: (progress: number) => void,
    ): Promise<string> => {
      try {
        const now = new Date().getTime();
        lastInvoke = now;

        // TODO: Move up as a function
        // delete all the caches that expired and release the memory
        Array.from(blobCache.keys())
          .filter((key) => {
            return blobCache.get(key)!.expired <= now || key === '';
          })
          .forEach((key) => {
            window.URL.revokeObjectURL(blobCache.get(key)?.url || '');
            blobCache.delete(key);
          });

        // check if the not expired caches exist
        if (blobCache.has(file.uuid)) {
          // if there is the cache, refresh the expired and return the blob
          const { url: blobUrl = '' } = blobCache.get(file.uuid) || {};

          blobCache.set(file.uuid, {
            expired: now + EXPIRED_DURATION,
            url: blobUrl,
          });
          updateProgress && updateProgress(100);

          return blobUrl;
        } else {
          // if there is no the cache, request to server
          const promise = fileService.readPdfWithoutOCR(file, updateProgress);

          const pdfFileUrl = await promise;
          if (pdfFileUrl !== undefined) {
            return pdfFileUrl;
          } else {
            throw Error(
              'Invalid response format when fetching raw file with uuid: ' +
                file.uuid +
                '',
            );
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('file service error: ', error.message);
        }
        return '';
      }
    },
    [],
  );

  const getFileMetadata = useCallback(
    async (uuid: TypeUUID): Promise<FileMetadataV2 | undefined> => {
      try {
        // if there is no the cache, request to server
        const promise = fileService.readMetadata(uuid);

        const response = await promise;
        if (response !== undefined) {
          return response as FileMetadataV2;
        } else {
          throw Error(
            'Invalid response format when fetching metadata for file by uuid: ' +
              uuid +
              '',
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('file service error: ', error.message);
        }
        return undefined;
      }
    },
    [],
  );

  if (!job.running) {
    job.start();
  }

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, [abortController]);

  return {
    getFile,
    getFileMetadata,
    getRawFile,
  };
}
