import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/lib/display/api.js';
import { RenderingCancelledException } from 'pdfjs-dist/lib/display/display_utils.js';
import { RenderingStates } from 'pdfjs-dist/lib/web/pdf_rendering_queue.js';
import { PDFFindController, PDFViewer } from 'pdfjs-dist/web/pdf_viewer.js';
import {
  HighlightScaledRect,
  PdfHighlight,
  PdfHighlighter,
  PdfHighlighterProps,
  PdfViewMode,
} from 'react-pdf-highlighter';

import { isPunctuation, splitWordByPunctuation } from '../../helpers/utils';
import { FileInfoV2 } from '../../services/SearchService';

interface Word {
  isPunctuation: boolean;
  text: string;
}

export type MoveDirection = 'next' | 'prev';

export interface SearchTerm {
  color: string;
  term: string;
}

export interface PdfDetailData {
  file: FileInfoV2;
  highlight?: PdfHighlight;
  searchTerms: SearchTerm[];
}

PDFPageProxy.prototype._getTextContent = PDFPageProxy.prototype.getTextContent;
PDFPageProxy.prototype.getTextContent = function (params = {}) {
  return this._getTextContent(params).then((textContent: any) => {
    this.textContentItems = textContent.items || [];
    return textContent;
  });
};

export class CustomizedPdfHighlighter extends PdfHighlighter {
  idle = false;
  pdfFindController: CustomizedPDFFindController;
  pdfViewMode: PdfViewMode;

  constructor(props: PdfHighlighterProps<PdfHighlight>) {
    super(props);
    this.pdfFindController = new CustomizedPDFFindController({
      eventBus: this.eventBus,
      linkService: this.linkService,
    });
    this.pdfViewMode = props.pdfViewMode;
  }

  cancelExtractText(): void {
    for (let i = 0; i < this.viewer.pdfDocument.numPages; i++) {
      this.viewer.pdfDocument
        .getPage(i + 1)
        .then((page: typeof PDFPageProxy) => page.cancelExtractText());
    }
  }

  componentDidUpdate(prevProps: PdfHighlighterProps<PdfHighlight>): void {
    if (prevProps.pdfDocument !== this.props.pdfDocument) {
      this.init();
      return;
    }
    if (
      prevProps.highlights !== this.props.highlights ||
      prevProps.highlightTransform !== this.props.highlightTransform
    ) {
      this.renderHighlights(this.props);
    }
  }

  init(): void {
    const { pdfDocument } = this.props;
    pdfDocument.getPage = async function (pageNumber) {
      const page = await PDFDocumentProxy.prototype.getPage.apply(pdfDocument, [
        pageNumber,
      ]);
      return page;
    };

    this.viewer =
      this.viewer ||
      new PDFViewer({
        container: this.containerNode,
        enhanceTextSelection: true,
        eventBus: this.eventBus,
        findController: this.pdfFindController,
        linkService: this.linkService,
        pdfViewMode: this.pdfViewMode,
        removePageBorders: true,
      });

    this.linkService.setDocument(pdfDocument);
    this.linkService.setViewer(this.viewer);
    this.viewer.setDocument(pdfDocument);

    this.pdfFindController.setDocument(pdfDocument);
    this.pdfFindController.setViewer(this.viewer);

    this.viewer.renderingQueue.onIdle = () => {
      this.idle = true;
    };

    // Override "renderingQueue.renderView" for reset page view when has "RenderingCancelledException"
    this.viewer.renderingQueue.renderView = function (view: any) {
      switch (view.renderingState) {
        case RenderingStates.FINISHED:
          return false;
        case RenderingStates.PAUSED:
          this.highestPriorityPage = view.renderingId;
          view.resume();
          break;
        case RenderingStates.RUNNING:
          this.highestPriorityPage = view.renderingId;
          break;
        case RenderingStates.INITIAL:
          this.highestPriorityPage = view.renderingId;
          view
            .draw()
            .finally(() => {
              this.renderHighestPriority();
            })
            .catch((reason: Error) => {
              if (reason instanceof RenderingCancelledException) {
                view.reset();
                return;
              }
              console.error(`renderView: "${reason}"`);
            });
          break;
      }
      return true;
    };

    this.viewer.setScale = (scale: number | string) => {
      let _scale = scale;
      if (typeof _scale === 'number') {
        _scale = Math.round(_scale * 100) / 100;
      }
      if (this.viewer.currentScaleValue !== _scale) {
        this.viewer.currentScaleValue = _scale;
      }
    };

    // Disable annotation layer for "preview" mode
    if (this.pdfViewMode === 'preview') {
      this.eventBus.on('pagesinit', () => {
        for (let i = 0; i < this.viewer.pagesCount; i++) {
          const pageView = this.viewer.getPageView(i);
          if (pageView) {
            pageView.annotationLayerFactory = null;
          }
        }
      });
    }
  }
}

interface Match {
  beginIndex: number;
  keyword: string;
  matchLength: number;
}

export class CustomizedPDFFindController extends PDFFindController {
  _mergedPageMatches: number[][] = [];
  _mergedPageMatchesLength: number[][] = [];
  _stoppedFind = false;
  _wordsByPage: Array<Word[]> = [];

  matchCountByPage: number[] = [];
  matchCountByTermAndPage: Record<string, number[]> = {};
  pageMatchesTerm: string[][] = [];

  constructor(opts: Record<string, unknown>) {
    super(opts);
    this._cachedPageMatches = null;
    this._cachedPageMatchesLength = null;
  }

  _calculatePhraseMatch(q: string, pageIndex: number): void {
    const matches: Match[] = [];

    this._state.queries.forEach((query: string) => {
      const queryWords = splitWordByPunctuation(query)
        .filter((w) => !isPunctuation(w))
        .map((w) => w.toLowerCase());

      const pageWords = this.getPageWords(pageIndex);
      const [pageMatches, pageMatchesLength] = this._calculateWordsMatch(
        pageIndex,
        queryWords,
        pageWords,
      );

      for (let i = 0; i < pageMatches.length; i++) {
        matches.push({
          beginIndex: pageMatches[i],
          keyword: query,
          matchLength: pageMatchesLength[i],
        });
      }

      if (!this.matchCountByTermAndPage[query]) {
        this.matchCountByTermAndPage[query] = [];
      }
      if (this.matchCountByTermAndPage[query][pageIndex] === undefined) {
        this.matchCountByTermAndPage[query][pageIndex] = 0;
      }
      this.matchCountByTermAndPage[query][pageIndex] += pageMatches.length;
    });

    this._pageMatches[pageIndex] = [];
    this._pageMatchesLength[pageIndex] = [];
    this.pageMatchesTerm[pageIndex] = [];

    matches
      .sort((m1, m2) => m1.beginIndex - m2.beginIndex)
      .forEach(({ beginIndex, keyword, matchLength }) => {
        this._pageMatches[pageIndex].push(beginIndex);
        this._pageMatchesLength[pageIndex].push(matchLength);
        this.pageMatchesTerm[pageIndex].push(keyword);
      });

    if (this.matchCountByPage[pageIndex] === undefined) {
      this.matchCountByPage[pageIndex] = 0;
    }
    this.matchCountByPage[pageIndex] += matches.length;
  }

  _calculateWordsMatch(
    pageIndex: number,
    queryWords: string[],
    pageWords: Word[],
  ): number[][] {
    if (this._stoppedFind) {
      return [[], []];
    }

    const matches: number[] = [];
    const matchesLength: number[] = [];

    let currentPageWordIndex = 0;
    let currentPageCharIndex = 0;

    while (currentPageWordIndex < pageWords.length) {
      let queryWordIndex = 0;
      let pageWordIndex = currentPageWordIndex;
      let matchLength = 0;

      while (queryWordIndex < queryWords.length) {
        // No more word in page
        // Point currentPageWordIndex to non-exist index, it will stop the calculate matching for this page
        if (pageWordIndex >= pageWords.length) {
          currentPageWordIndex = pageWordIndex;
          break;
        }

        const { isPunctuation, text: pageWord } = pageWords[pageWordIndex];

        let skipFindMatch = this.isMatchedWord(
          pageIndex,
          pageWord,
          currentPageCharIndex,
        );
        if (!skipFindMatch && isPunctuation) {
          if (matchLength > 0) {
            // There is query word matched, continue to find next match of query word from next page word
            pageWordIndex++;
            matchLength += pageWord.length;
            continue;
          } else {
            skipFindMatch = true;
          }
        }

        if (skipFindMatch) {
          currentPageCharIndex += pageWords[currentPageWordIndex].text.length;
          currentPageWordIndex++;
          break;
        }

        const queryWord = queryWords[queryWordIndex];
        const matchIdx = pageWord.indexOf(queryWord, 0);

        // If query word and page word isn't match
        // Find query match again from next page word
        if (
          matchIdx === -1 ||
          !this.isEntireWord(pageWord, matchIdx, queryWord.length)
        ) {
          currentPageCharIndex += pageWords[currentPageWordIndex].text.length;
          currentPageWordIndex++;
          break;
        }

        // Query word and page word is match
        // Move to next query word and page word
        queryWordIndex++;
        pageWordIndex++;
        matchLength += pageWord.length;

        // No more query word, it means the query is match entirely
        // Add match index and length, then find next matching
        if (queryWordIndex >= queryWords.length) {
          this.addPageMatches(pageIndex, currentPageCharIndex, matchLength);
          matches.push(currentPageCharIndex);
          matchesLength.push(matchLength);
          currentPageWordIndex = pageWordIndex;
          currentPageCharIndex += matchLength;
          break;
        }
      }
    }

    return [matches, matchesLength];
  }

  _getPageWords(pageIndex: number): Word[] {
    const textContentItems =
      this.viewer.getPageView(pageIndex)?.pdfPage?.textContentItems;
    if (!textContentItems) {
      return [];
    }

    const words: Word[] = [];
    let lineWords: string[] = [];

    textContentItems.forEach((item: any, i: number) => {
      lineWords.push(item.str);

      if (item.hasEOL || i === textContentItems.length - 1) {
        words.push(
          ...splitWordByPunctuation(lineWords.join('').toLowerCase()).map(
            (str) => ({
              isPunctuation: isPunctuation(str),
              text: str,
            }),
          ),
        );
        lineWords = [];
      }
    });

    return words;
  }

  addPageMatches(
    pageIndex: number,
    matchIndex: number,
    matchLength: number,
  ): void {
    if (!this._mergedPageMatches[pageIndex]) {
      this._mergedPageMatches[pageIndex] = [];
      this._mergedPageMatchesLength[pageIndex] = [];
    }

    this._mergedPageMatches[pageIndex].push(matchIndex);
    this._mergedPageMatchesLength[pageIndex].push(matchLength);
  }

  getPageMatches(pageIndex: number): number[][] {
    return [
      this._mergedPageMatches[pageIndex] || [],
      this._mergedPageMatchesLength[pageIndex] || [],
    ];
  }

  getPageWords(pageIndex: number): Word[] {
    if (!this._wordsByPage[pageIndex]) {
      this._wordsByPage[pageIndex] = this._getPageWords(pageIndex);
    }

    return this._wordsByPage[pageIndex] || [];
  }

  isEntireWord(content: string, startIdx: number, length: number): boolean {
    return this._isEntireWord(content, startIdx, length);
  }

  isMatchedWord(pageIndex: number, word: string, wordIndex: number): boolean {
    const [pageMatches, pageMatchesLength] = this.getPageMatches(pageIndex);

    for (let i = 0; i < pageMatches.length; i++) {
      const pageMatch = pageMatches[i];
      const pageMatchLength = pageMatchesLength[i];
      if (
        pageMatch <= wordIndex &&
        wordIndex + word.length <= pageMatch + pageMatchLength
      ) {
        return true;
      }
    }

    return false;
  }

  setViewer(viewer: typeof PDFViewer): void {
    this.viewer = viewer;
  }

  stopFind(): void {
    this._stoppedFind = true;
  }

  get _scrollMatches(): any {
    // Disable auto scroll to match
    return false;
  }

  set _scrollMatches(scrollMatches: unknown) {
    // Do nothing
  }

  set cachedPageMatches(cachedPageMatches: [][]) {
    this._cachedPageMatches = cachedPageMatches;
  }

  set cachedPageMatchesLength(cachedPageMatchesLength: [][]) {
    this._cachedPageMatchesLength = cachedPageMatchesLength;
  }

  get pageMatches(): any {
    return this._cachedPageMatches || this._pageMatches;
  }

  get pageMatchesLength(): any {
    return this._cachedPageMatchesLength || this._pageMatchesLength;
  }
}

export const getNextId = (): string => String(Math.random()).slice(2);

export const addHighlight = (
  highlights: PdfHighlight[],
  highlight: PdfHighlight,
  viewer: typeof PDFViewer,
): void => {
  if (!hasOverlapHighlight(highlights, highlight, viewer)) {
    highlights.push(highlight);
  }
};

const hasOverlapHighlight = (
  highlights: PdfHighlight[],
  highlight: PdfHighlight,
  viewer: typeof PDFViewer,
): boolean => {
  for (let i = 0; i < highlights.length; i++) {
    if (isHighlightIntersect(highlights[i], highlight, viewer)) {
      return true;
    }
  }

  return false;
};

const isHighlightIntersect = (
  h1: PdfHighlight,
  h2: PdfHighlight,
  viewer: typeof PDFViewer,
): boolean => {
  if (h1.position.pageNumber !== h2.position.pageNumber) {
    return false;
  }

  for (let i = 0; i < h1.position.rects.length; i++) {
    const rect1 = h1.position.rects[i];
    for (let j = 0; j < h2.position.rects.length; j++) {
      const rect2 = h2.position.rects[j];
      if (isRectIntersect(h1.position.pageNumber, rect1, rect2, viewer)) {
        return true;
      }
    }
  }
  return false;
};

const isRectIntersect = (
  pageNumber: number,
  rect1: HighlightScaledRect,
  rect2: HighlightScaledRect,
  viewer: typeof PDFViewer,
): boolean => {
  if (rect1.line !== rect2.line) {
    // rects in 2 lines cannot intersect
    return false;
  }

  const scaledRect1 = normalizeRectScale(pageNumber, rect1, viewer);
  const scaledRect2 = normalizeRectScale(pageNumber, rect2, viewer);
  return !(
    scaledRect2.x2 <= scaledRect1.x1 ||
    scaledRect1.x2 <= scaledRect2.x1 ||
    scaledRect2.y2 <= scaledRect1.y1 ||
    scaledRect1.y2 <= scaledRect2.y1
  );
};

export const normalizeRectScale = (
  pageNumber: number,
  rect: HighlightScaledRect,
  viewer: typeof PDFViewer,
): HighlightScaledRect => {
  const pageView = viewer.getPageView(pageNumber - 1);
  if (!pageView) {
    return rect;
  }

  const scale = {
    x: pageView.width / rect.width,
    y: pageView.height / rect.height,
  };

  return {
    height: pageView.height,
    width: pageView.width,
    x1: rect.x1 * scale.x,
    x2: rect.x2 * scale.x,
    y1: rect.y1 * scale.y,
    y2: rect.y2 * scale.y,
  };
};

export const sortHighlight = (h1: PdfHighlight, h2: PdfHighlight): number => {
  if (h1.position.pageNumber !== h2.position.pageNumber) {
    return sortHighlightByPage(h1, h2);
  }

  if (h1.position.scaledBoundingRect.y1 !== h2.position.scaledBoundingRect.y1) {
    return sortHighlightByTop(h1, h2);
  }

  return sortHighlightByLeft(h1, h2);
};

const sortHighlightByPage = (h1: PdfHighlight, h2: PdfHighlight): number =>
  h1.position.pageNumber - h2.position.pageNumber;

const sortHighlightByTop = (h1: PdfHighlight, h2: PdfHighlight): number =>
  h1.position.scaledBoundingRect.y1 - h2.position.scaledBoundingRect.y1;

const sortHighlightByLeft = (h1: PdfHighlight, h2: PdfHighlight): number =>
  h1.position.scaledBoundingRect.x1 - h2.position.scaledBoundingRect.x1;

export const groupHighlightByTermAndPage = (
  highlights: PdfHighlight[],
): Record<string, PdfHighlight[][]> => {
  return [...highlights].sort(sortHighlightByPage).reduce(
    (textHighlightsByTermAndPage, h) => {
      const {
        content: { text: term },
        position: { pageNumber },
      } = h;

      if (!textHighlightsByTermAndPage[term]) {
        textHighlightsByTermAndPage[term] = [];
      }

      const pageNo = pageNumber - 1;
      if (!textHighlightsByTermAndPage[term][pageNo]) {
        textHighlightsByTermAndPage[term][pageNo] = [];
      }

      h.position.indexInPage = textHighlightsByTermAndPage[term][pageNo].length;

      textHighlightsByTermAndPage[term][pageNo].push(h);

      return textHighlightsByTermAndPage;
    },
    {} as Record<string, PdfHighlight[][]>,
  );
};

const firstOrLast = (direction: MoveDirection, matchCount: number) =>
  direction === 'next' ? 0 : matchCount - 1;
const nextOrPrev = (direction: MoveDirection) =>
  direction === 'next' ? 1 : -1;

export const findNearHighlight = (
  searchTerm: string,
  direction: MoveDirection,
  highlights: PdfHighlight[],
  matchCountByTermAndPage: Record<string, Record<number, number>>,
  currentHighlight?: PdfHighlight,
): [number, number, number] => {
  const sortedMatchCountByPage = Object.entries(
    matchCountByTermAndPage[searchTerm] || {},
  )
    .map(([pageNo, count]) => ({
      matchCount: count,
      pageNo: parseInt(pageNo, 10),
    }))
    .filter(({ matchCount }) => matchCount > 0)
    .sort(({ pageNo: pageNo1 }, { pageNo: pageNo2 }) =>
      direction === 'next' ? pageNo1 - pageNo2 : pageNo2 - pageNo1,
    );
  const calculateHighlightIndex = (
    pageNo: number,
    matchInPageIndex: number,
  ): [number, number, number] => {
    const highlightIndex = sortedMatchCountByPage
      .filter((p) => p.pageNo < pageNo)
      .reduce((sum, { matchCount }) => sum + matchCount, matchInPageIndex);
    return [pageNo, matchInPageIndex, highlightIndex];
  };

  // When change search term
  // move to first or last highlight of new search term based on the direction
  if (searchTerm !== currentHighlight?.content.text) {
    const firstPageHasMatch = sortedMatchCountByPage[0];
    if (firstPageHasMatch) {
      return calculateHighlightIndex(
        firstPageHasMatch.pageNo,
        firstOrLast(direction, firstPageHasMatch.matchCount),
      );
    }
    return [-1, -1, -1];
  }

  const currentPageNumber = currentHighlight.position.pageNumber;
  const currentPageNo = currentPageNumber - 1;

  const sortedHighlightInSamePage = highlights
    .filter(({ content: { text }, position: { pageNumber } }) => {
      return (
        currentHighlight.content.text.includes(text) &&
        pageNumber === currentPageNumber
      );
    })
    .sort(sortHighlight);

  const currentIndex = sortedHighlightInSamePage.findIndex(
    (h) => h.id === currentHighlight.id,
  );
  const nearIndex = currentIndex + nextOrPrev(direction);

  const { matchCount, pageNo } = sortedMatchCountByPage.find(
    ({ pageNo }) => pageNo === currentPageNo,
  ) || {
    matchCount: -1,
    pageNo: -1,
  };

  // When page has more highlight, move to it
  if (0 <= nearIndex && nearIndex <= matchCount - 1) {
    return calculateHighlightIndex(pageNo, nearIndex);
  }

  // When page doesn't have more highlight
  // Move to next page has match, and focus on first/last based on the direction
  const pageIndex = sortedMatchCountByPage.findIndex(
    (p) => p.pageNo === currentPageNo,
  );
  const nearPage =
    sortedMatchCountByPage[(pageIndex + 1) % sortedMatchCountByPage.length];
  return calculateHighlightIndex(
    nearPage.pageNo,
    firstOrLast(direction, nearPage.matchCount),
  );
};

// interface PageMatch {
// 	pageNo: number;
// 	matchCount: number;
// 	prevMatchCount: number;
// }

export const indexHighlightByTermAndPage = (
  highlights: PdfHighlight[],
  matchCountByTermAndPage: Record<string, Record<number, number>>,
): void => {
  // const prevMatchCountByTermAndPage = Object.entries(matchCountByTermAndPage).reduce(
  // 	(res, [term, matchCountByPage]) => {
  // 		const prevMatchCountByPage = Object.entries(matchCountByPage)
  // 			.map(([pageNo, count]) => ({
  // 				pageNo: parseInt(pageNo, 10),
  // 				matchCount: count,
  // 				prevMatchCount: 0,
  // 			}))
  // 			.sort(({ pageNo: pageNo1 }, { pageNo: pageNo2 }) => pageNo1 - pageNo2);

  // 		prevMatchCountByPage.forEach((p, i, pages) => {
  // 			if (i > 0) {
  // 				const prevPage = pages[i - 1];
  // 				p.prevMatchCount = prevPage.prevMatchCount + prevPage.matchCount;
  // 			}
  // 		});

  // 		res[term] = prevMatchCountByPage;

  // 		return res;
  // 	},
  // 	{} as Record<string, PageMatch[]>,
  // );

  const highlightByTermAndPage = groupHighlightByTermAndPage(highlights);
  Object.values(highlightByTermAndPage).forEach((pages) => {
    pages.forEach((highlightsInPage) => {
      highlightsInPage?.sort(sortHighlight).forEach((h, index) => {
        // said Quan: logic of indexInFile covered in "useOcrHighlight.ts"
        // ref: src/components/pdf/useOcrHighlight.ts
        // const pageMatch = (prevMatchCountByTermAndPage[h.content.text] || [])[h.position.pageNumber - 1];
        // h.position.indexInFile = pageMatch ? pageMatch.prevMatchCount + index : 0;
        h.position.indexInPage = index;
      });
    });
  });
};
