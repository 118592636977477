import { msalInstance } from '@/helpers/msal';
import { atom } from 'jotai';
import { atomWithMutation, atomWithQuery } from 'jotai-tanstack-query';

export const tagsAtom = atom<string[]>([]);

export const searchLogQueryAtom = atomWithQuery(() => ({
  queryFn: async () => {
    const activeAccount = msalInstance.getActiveAccount();

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/user/search-logs?user_email=${activeAccount?.username}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        },
      },
    );
    const data = await res.json();
    return data;
  },
  queryKey: [
    'searchLogs',
    process.env.REACT_APP_API_URL,
    process.env.REACT_APP_BEARER_TOKEN,
  ],
}));

// export const createSearchLogAtom = atomWithMutation((get) => ({
//   mutationFn: async ({
//     displayType,
//     projectCodes,
//     tags,
//   }: {
//     displayType: string;
//     projectCodes: string[];
//     tags: string[];
//   }) => {
//     const activeAccount = msalInstance.getActiveAccount();

//     const res = await fetch(
//       `${process.env.REACT_APP_API_URL}/user/search-logs`,
//       {
//         method: 'POST',
//         body: JSON.stringify({
//           terms: tags,
//           user_email: activeAccount?.username,
//           project_codes: projectCodes,
//           view_type: displayType,
//         }),
//         headers: {
//           Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
//           'Content-Type': 'application/json',
//         },
//       },
//     );
//     const data = await res.json();
//     return data;
//   },
//   onSuccess: () => {
//     get(searchLogQueryAtom).refetch();
//   },
// }));

export const removeSearchLogAtom = atomWithMutation((get) => ({
  mutationFn: async (id: string) => {
    await fetch(`${process.env.REACT_APP_API_URL}/user/search-logs/${id}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
      method: 'DELETE',
    });
  },
  onSuccess: () => {
    get(searchLogQueryAtom).refetch();
  },
}));
