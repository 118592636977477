import { FileInfoV2 } from '@/services/SearchService';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { CloseModalIcon } from '../icons';

type HoverableDetailTitleProps = {
  file: FileInfoV2;
  onHide: VoidFunction;
  title: string;
};

export const HoverableDetailTitle = ({
  file,
  onHide,
  title,
}: HoverableDetailTitleProps) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="relative flex w-full flex-grow items-center">
      <div className="flex w-full flex-grow items-center justify-center">
        <h4 className="max-w-[70%] truncate">{title}</h4>

        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              arrow
              classes={{
                arrow: '!text-[#FCFCFC]',
                popper: '!z-[1501]',
                tooltip:
                  'mt-0 min-w-[400px] !bg-[#FCFCFC] !px-6 !py-3 !text-[#111] shadow-2xl',
              }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipClose}
              open={open}
              title={
                <>
                  <div className="text-base !font-semibold">
                    Document Details
                  </div>

                  <div className="mt-3 grid grid-cols-[136px_1fr] gap-x-2 !text-sm">
                    <div className="font-semibold">Project Code:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.project_code
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(file?.project_code ?? '');

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.project_code}
                    </div>

                    <div className="font-semibold">Project Name:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.project_name
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(file?.project_name ?? '');

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.project_name}
                    </div>

                    <div className="font-semibold">Document Number:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.document_number
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          file?.document_number ?? '',
                        );

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.document_number}
                    </div>

                    <div className="font-semibold">Title:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.title ? 'cursor-pointer' : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(file?.title ?? '');

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.title}
                    </div>

                    <div className="font-semibold">Revision:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.revision
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(file?.revision ?? '');

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.revision}
                    </div>

                    <div className="font-semibold">Status:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.state ? 'cursor-pointer' : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(file?.state ?? '');

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.state}
                    </div>

                    <div className="font-semibold">SDRL Code:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.sdrl_code
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(file?.sdrl_code ?? '');

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.sdrl_code}
                    </div>

                    <div className="font-semibold">Document Type:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.document_type
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          file?.document_type ?? '',
                        );

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.document_type}
                    </div>

                    <div className="font-semibold">Type of Document:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.type_of_document
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          file?.type_of_document ?? '',
                        );

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.type_of_document}
                    </div>

                    <div className="font-semibold">Discipline:</div>
                    <div
                      className={twMerge(
                        'hover:text-[#666]',
                        file?.type_of_document
                          ? 'cursor-pointer'
                          : 'pointer-events-none',
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          file?.type_of_document ?? '',
                        );

                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      {file?.discipline}
                    </div>
                  </div>
                </>
              }
            >
              <IconButton onClick={handleTooltipOpen}>
                <InfoOutlinedIcon className="text-white" fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>

      <CloseModalIcon
        className="ic close-modal-icon absolute right-0"
        onClick={onHide}
      />
    </div>
  );
};
