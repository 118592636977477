import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  PopupRequest,
} from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '',
    // redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    // postLogoutRedirectUri: process.env.REACT_APP_AZURE_POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_AZURE_REDIRECT_SCOPES || ''],
};
