import Detail from '@/pages/Detail';
import Home from '@/pages/Home';
import Logout from '@/pages/Logout';
import Results from '@/pages/Results';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import App from './App';
import { PageUrl } from './constants';
import DocumentDetail from './pages/DocumentDetail';

export const router = createBrowserRouter([
  {
    children: [
      {
        element: <Logout />,
        path: PageUrl.LOGOUT,
      },
      {
        element: <Home />,
        path: PageUrl.HOME,
      },
      {
        children: [
          {
            element: <DocumentDetail />,
            path: '/doc/:documentNumber',
          },
          {
            element: <Results />,
            path: PageUrl.RESULTS,
          },
          {
            element: <Detail />,
            path: PageUrl.DETAIL,
          },
        ],
        element: (
          <>
            <AuthenticatedTemplate>
              <Outlet />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Navigate to={PageUrl.HOME} />
            </UnauthenticatedTemplate>
          </>
        ),
      },
    ],
    element: <App />,
  },
]);
