import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

import { PageUrl } from '../constants';
import { loginRequest, msalConfig } from '../constants/msalConfig';

export const ACCOUNT_STORAGE_KEY = 'UserAccount';

export const msalInstance = new PublicClientApplication(msalConfig);

const getInstance = (() => {
  let msalInstance: PublicClientApplication;

  return () => {
    if (!msalInstance) {
      msalInstance = new PublicClientApplication(msalConfig);
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      msalInstance.addEventCallback((event: EventMessage) => {
        if (
          (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
          event.payload
        ) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;

          msalInstance.setActiveAccount(account);

          if (account) {
            setAccount(account);
          }
          // else {
          // 	removeAccount();
          // }
        } else if (
          event.eventType === EventType.SSO_SILENT_FAILURE &&
          window.location.pathname !== PageUrl.LOGIN &&
          (!accounts || !accounts.length)
        ) {
          // Case: access url if unauthenticated
          window.location.href = PageUrl.LOGIN;
        }
      });

      // Logout all tabs
      window.onstorage = function () {
        if (!getAccount()) {
          window.location.href = PageUrl.LOGIN;
        }
      };
    }

    return msalInstance;
  };
})();

const getAccount = (): AccountInfo | undefined => {
  if (!window || !window.localStorage) {
    return undefined;
  }

  try {
    const account = localStorage.getItem(ACCOUNT_STORAGE_KEY);
    if (account) {
      return JSON.parse(account);
    }
  } catch (e) {
    // Case: user edit localStorage that is not a json format manually
    removeAccount();
    return undefined;
  }
};

const setAccount = (account: AccountInfo): void =>
  localStorage.setItem(ACCOUNT_STORAGE_KEY, JSON.stringify(account));

const removeAccount = (): void => localStorage.removeItem(ACCOUNT_STORAGE_KEY);

const silentLoginAsync = (): Promise<AuthenticationResult | void> => {
  const account = getAccount();
  return getInstance()
    .ssoSilent({
      ...loginRequest,
      account,
    })
    .catch((error) => {
      // Case: Not duplicate tab
      console.error({ silentLoginAsyncError: error });
      if (
        !account &&
        error.errorCode !== 'block_iframe_reload' &&
        error.errorCode !== 'silent_sso_error'
      ) {
        removeAccount();
      }
    });
};

const getAccessTokenAsync = async (): Promise<string | undefined> => {
  const msalInstance = getInstance();
  const userAccount = msalInstance.getActiveAccount();
  if (!userAccount) {
    window.location.href = PageUrl.LOGIN;
    return undefined;
  } else {
    const request = {
      ...loginRequest,
      account: userAccount,
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  }
};

export const msal = {
  getAccessTokenAsync,
  getAccount,
  getInstance,
  removeAccount,
  setAccount,
  silentLoginAsync,
};
