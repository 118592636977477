import { ReactComponent as AccessIcon } from '@/assets/icons/native-file/access.svg';
import { ReactComponent as AutocadIcon } from '@/assets/icons/native-file/autocad.svg';
import { ReactComponent as CodeIcon } from '@/assets/icons/native-file/code.svg';
import { ReactComponent as CompressedFileIcon } from '@/assets/icons/native-file/compressed-file.svg';
import { ReactComponent as ExcelIcon } from '@/assets/icons/native-file/excel.svg';
import { ReactComponent as GenericIcon } from '@/assets/icons/native-file/generic.svg';
import { ReactComponent as ImageIcon } from '@/assets/icons/native-file/image.svg';
import { ReactComponent as NavisworkIcon } from '@/assets/icons/native-file/naviswork.svg';
import { ReactComponent as OneNoteIcon } from '@/assets/icons/native-file/one-note.svg';
import { ReactComponent as OutlookIcon } from '@/assets/icons/native-file/outlook.svg';
import { ReactComponent as PdfIcon } from '@/assets/icons/native-file/pdf.svg';
import { ReactComponent as PowerpointIcon } from '@/assets/icons/native-file/powerpoint.svg';
import { ReactComponent as ProjectIcon } from '@/assets/icons/native-file/project.svg';
import { ReactComponent as VideoIcon } from '@/assets/icons/native-file/video.svg';
import { ReactComponent as VisioIcon } from '@/assets/icons/native-file/visio.svg';
import { ReactComponent as WordIcon } from '@/assets/icons/native-file/word.svg';
import { NativeFile } from '@/atoms/nativeFiles';
import { SvgIcon } from '@mui/material';

export default function NativeFileIcon({ file }: { file: NativeFile }) {
  const lastIndex = file.title.lastIndexOf('.');

  if (lastIndex === -1) {
    return (
      <SvgIcon>
        <GenericIcon />
      </SvgIcon>
    );
  }

  switch (file.title.substring(lastIndex + 1)) {
    case 'mdb':
      return (
        <SvgIcon>
          <AccessIcon />
        </SvgIcon>
      );

    case 'dgn':
    case 'dxf':
    case 'dwf':
    case 'dwg':
    case 'step':
    case 'stp':
      return (
        <SvgIcon>
          <AutocadIcon />
        </SvgIcon>
      );

    case 'htm':
    case 'html':
    case 'txt':
    case 'bak':
    case 'tbr':
    case 'xml':
      return (
        <SvgIcon>
          <CodeIcon />
        </SvgIcon>
      );

    case '7z':
    case 'rar':
    case 'zip':
      return (
        <SvgIcon>
          <CompressedFileIcon />
        </SvgIcon>
      );

    case 'csv':
    case 'xls':
    case 'xlsb':
    case 'xlsm':
    case 'xlsx':
    case 'xlt':
    case 'xltx':
      return (
        <SvgIcon>
          <ExcelIcon />
        </SvgIcon>
      );

    case 'bmp':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'tif':
      return (
        <SvgIcon>
          <ImageIcon />
        </SvgIcon>
      );

    case 'nwd':
      return (
        <SvgIcon>
          <NavisworkIcon />
        </SvgIcon>
      );

    case 'one':
      return (
        <SvgIcon>
          <OneNoteIcon />
        </SvgIcon>
      );

    case 'msg':
      return (
        <SvgIcon>
          <OutlookIcon />
        </SvgIcon>
      );

    case 'pdf':
    case 'fdf':
      return (
        <SvgIcon>
          <PdfIcon />
        </SvgIcon>
      );

    case 'pot':
    case 'potx':
    case 'ppsx':
    case 'ppt':
    case 'pptx':
      return (
        <SvgIcon>
          <PowerpointIcon />
        </SvgIcon>
      );

    case 'mpp':
      return (
        <SvgIcon>
          <ProjectIcon />
        </SvgIcon>
      );

    case '3gp':
    case 'mp4':
    case 'mpg':
    case 'wmv':
      return (
        <SvgIcon>
          <VideoIcon />
        </SvgIcon>
      );

    case 'vsd':
    case 'vsdx':
      return (
        <SvgIcon>
          <VisioIcon />
        </SvgIcon>
      );

    case 'doc':
    case 'docm':
    case 'docx':
    case 'dot':
    case 'dotm':
    case 'dotx':
    case 'odt':
    case 'rtf':
      return (
        <SvgIcon>
          <WordIcon />
        </SvgIcon>
      );
  }

  return (
    <SvgIcon>
      <GenericIcon />
    </SvgIcon>
  );
}
