import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';

export const projectListAtom = atomWithQuery(() => ({
  queryFn: async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/search/project_code/bing`,
    );
    return await res.json();
  },
  queryKey: ['projects', process.env.REACT_APP_API_URL],
}));

export type ProjectOption = {
  label: string;
  value: string;
};

export const projectOptionListAtom = atom<ProjectOption[]>((get) => {
  const projectList = get(projectListAtom).data;

  return projectList
    ? Object.entries<string>(projectList)
        .map(([key, value]) => ({
          label: value,
          value: key,
        }))
        .sort((a, b) => (a.value > b.value ? -1 : 1))
    : [];
});

export const selectedProjectsAtom = atom<ProjectOption[]>([]);

export const pendingProjectsAtom = atom<ProjectOption[]>([]);
