import useFileDownloadLinkQuery from '@/queries/useFileDownloadLinkQuery';
import useNativeFileAvailabilityQuery from '@/queries/useNativeFileAvailabilityQuery';
import { clarityService, DOWNLOAD_OPTION } from '@/services';
import { FileInfoV2 } from '@/services/SearchService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Popper,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import NativeFiles from '../commons/NativeFiles';
import { DefaultFileIcon, FileDownloadIcon } from '../icons';

export default function DocumentPopper({
  file,
  viewFile,
}: {
  file: FileInfoV2;
  viewFile: VoidFunction;
}) {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const detailPageUrl = useMemo(() => {
    searchParams.set('document_number', file.document_number);
    searchParams.set('revision', file.revision);
    searchParams.set('uuid', file.uuid);

    return searchParams.toString();
  }, [file.document_number, file.revision, file.uuid, searchParams]);

  const nativeFileAvailabilityQuery = useNativeFileAvailabilityQuery();

  const [enableDownloadLinkRequest, setEnableDownloadLinkRequest] =
    useState(false);

  const { data, isError, isLoading } = useFileDownloadLinkQuery({
    enabled: enableDownloadLinkRequest,
    uuid: file.uuid,
  });

  useEffect(() => {
    if (data) {
      const link = document.createElement('a');
      link.download = `${data.name}.${data.extension}`;
      link.href = `${data?.path}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      clarityService.trackingDownloadHistory(DOWNLOAD_OPTION.single, 1, file);
    }
  }, [data, file]);

  useEffect(() => {
    if (isError) {
      clarityService.trackingDownloadFailureHistory(
        DOWNLOAD_OPTION.single,
        1,
        file,
      );
    }
  }, [file, isError]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setAnchorEl(null);
        setEnableDownloadLinkRequest(false);
      }}
    >
      <div>
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVertIcon className="text-white" />
        </IconButton>

        <Popper
          anchorEl={anchorEl}
          open={open}
          placement="bottom-end"
          slotProps={{
            root: { className: 'z-[2]' },
          }}
        >
          <div className="min-w-[220px] rounded-lg bg-[#333] py-[6px] text-white">
            <button
              className="flex h-10 w-full justify-between px-3 py-2 hover:!bg-[#111]"
              onClick={viewFile}
            >
              View
            </button>

            {searchParams.get('preview') === 'true' && (
              <a
                className="flex h-10 w-full justify-between px-3 py-2 text-white hover:!bg-[#111] [&>svg]:mr-2"
                href={detailPageUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>{t('document_preview_text_preview_in_new_tab')}</span>
                <DefaultFileIcon height="24px" role="button" width="24px" />
              </a>
            )}

            <button
              className="flex h-10 w-full items-center justify-between px-3 py-2 hover:!bg-[#111]"
              onClick={() => {
                setEnableDownloadLinkRequest(true);
              }}
            >
              <span>Download PDF</span>

              {isLoading ? (
                <CircularProgress
                  className="!text-[rgba(255,255,255,0.56)]"
                  size={16}
                />
              ) : (
                <FileDownloadIcon height="24px" role="button" width="24px" />
              )}
            </button>

            {nativeFileAvailabilityQuery && <NativeFiles fileId={file.uuid} />}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
