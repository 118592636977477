import { documentIdAtom, documentRequestQueryAtom } from '@/atoms/nativeFiles';
import useNativeFileAvailabilityQuery from '@/queries/useNativeFileAvailabilityQuery';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Popper,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import NativeFileIcon from './NativeFileIcon';

export default function NativeFiles({ fileId }: { fileId: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    setTimeout(() => {
      setAnchorEl(null);
    }, 350000);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'native-files-popover' : undefined;

  const nativeFileAvailabilityQuery = useNativeFileAvailabilityQuery();

  const setDocumentId = useSetAtom(documentIdAtom);
  const documentRequestQuery = useAtomValue(documentRequestQueryAtom);

  useEffect(() => {
    setDocumentId(nativeFileAvailabilityQuery.data ? fileId : '');
  }, [fileId, nativeFileAvailabilityQuery.data, setDocumentId]);

  if (!nativeFileAvailabilityQuery.data) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button
          aria-describedby={id}
          className="h-10 w-full !justify-between px-3 py-2 !text-base !normal-case text-white hover:!bg-[#111]"
          onClick={handleClick}
          variant="text"
        >
          Native files
          <ChevronRightIcon />
        </Button>

        <Popper
          anchorEl={anchorEl}
          id={id}
          open={open}
          placement="right-start"
          slotProps={{ root: { className: 'z-[2]' } }}
        >
          <div className="rounded-lg bg-[#333]">
            {documentRequestQuery.isLoading ? (
              <div className="grid min-h-[52px] min-w-52 place-content-center">
                <CircularProgress
                  className="!text-[rgba(255,255,255,0.56)]"
                  size={20}
                />
              </div>
            ) : (
              <div className="flex min-h-[52px] min-w-[220px] flex-col py-[6px]">
                {documentRequestQuery.data?.length === 0 ? (
                  <div className="grid flex-grow place-content-center text-center">
                    No native files
                  </div>
                ) : (
                  documentRequestQuery.data?.map((file) => (
                    <a
                      className="flex !px-3 !py-2 text-white"
                      download={file.title}
                      href={file.url}
                      key={file.title}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <NativeFileIcon file={file} />

                      <div className="!mr-4 !ms-3">{file.title}</div>

                      <SaveAltIcon className="ms-auto" />
                    </a>
                  ))
                )}
              </div>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
